import { getImageProps } from 'next/image';

import type { FunctionComponent } from 'react';
import type { TypePictureProps } from './types';

/**
 * Picture
 */
export const Picture: FunctionComponent<TypePictureProps> = ({
  className,
  classNameImg,
  alt,
  sources,
  sizes,
}: TypePictureProps) => {
  const common = { alt, sizes };
  const srcSet = sources.map(
    (source) => getImageProps({ ...common, ...source }).props,
  ) as Array<ReturnType<typeof getImageProps>['props'] & { media?: number }>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { srcSet: _, ...rest } = srcSet.at(-1) || {};

  return (
    <picture {...{ className }}>
      {srcSet
        .sort(({ media: a = 0 }, { media: b = 0 }) => b - a)
        .map(({ srcSet, media, className, height, width, src }, index) => (
          <source
            key={`${src}-${index}`}
            srcSet={srcSet}
            className={className}
            height={height}
            width={width}
            {...(media && { media: `(min-width: ${media}px)` })}
          />
        ))}

      <img
        {...rest}
        {...(classNameImg && { className: classNameImg })}
        draggable={false}
      />
    </picture>
  );
};

Picture.displayName = 'Picture';
