/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import type { AppProps } from 'next/app';
import type { AppPropsWithLayout } from 'pages/_app';
import type { ReactElement, ReactNode } from 'react';

const LoginComponent = dynamic(
  () =>
    import('../passwordProtect/LoginComponent').then(
      (mod) => mod.LoginComponent,
    ),
  { ssr: true },
);

export function withPassword(
  Component: AppProps['Component'] & {
    getLayout?: (page: ReactElement) => ReactNode;
  },
) {
  return function WithPassword(props: AppPropsWithLayout<any>) {
    const [showPage, setShowPage] = useState<boolean | undefined>(undefined);
    const router = useRouter();

    useEffect(() => {
      const controller = new AbortController();

      fetch('/api/pageAuth/token', {
        method: 'POST',
        signal: controller.signal,
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            setShowPage(true);
          } else {
            setShowPage(false);
          }
        })
        .catch(() => null);

      return () => {
        controller.abort('Component Unmount');
      };
    }, [router.pathname]);

    if (showPage === undefined) {
      return null;
    }

    if (!showPage) {
      return <LoginComponent />;
    }

    if (showPage) {
      const Layout = Component?.getLayout;

      if (Layout) {
        return (
          <Layout {...props.pageProps}>
            <Component {...props} />
          </Layout>
        );
      } else {
        return <Component {...props} />;
      }
    }
  };
}
