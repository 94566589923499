import Link from 'next/link';

import type { FunctionComponent } from 'react';
import type { TypeNextLinkProps } from './types';

/**
 * NextLink
 */
export const NextLink: FunctionComponent<TypeNextLinkProps> = (
  props: TypeNextLinkProps,
) => <Link {...props} prefetch={false} legacyBehavior />;

NextLink.displayName = 'NextLink';
